import React from 'react';
import { css } from '@emotion/core';
import { Tablet, Desktop } from '../../constants/responsive';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import Arrow from '../../svgs/fee/Arrow-01.svg';

// 收费目录
const Category = ({ id, country }) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        padding: 22px 0 0 0;
        ${Tablet} {
          height: 18px;
          padding: 38.2px 0 41px 0;
        }
        ${Desktop} {
          height: 18px;
          padding: 38.2px 0 41px 0;
        }
      `}
    >
      <h2
        css={css`
          width: 100%;
          // padding-bottom: 12px;
          font-weight: bold;
          margin-bottom: 0px;
          font-size: 16px;
          line-height: 1.13;
          ${Tablet} {
            font-size: 20px;
            line-height: 1;
          }
          ${Desktop} {
            // padding-bottom: 24px;
            font-size: 20px;
            line-height: 1;
          }
        `}
      >
        <FormattedHTMLMessage id={`fee.category.${id}`} />
      </h2>
      <div
        css={css`
          width: 19.2px;
          height: 9.6px;
        `}
      >
        <Arrow
          width='100%'
          name='arrow'
          css={css`
            polyline {
              stroke-width: 90px;
            }
          `}
          style={{ transform: 'rotate(180deg)' }}
          onClick={(e) => {
            var section = document.getElementsByClassName(
              `${country}-${id}`
            )[0]; // 获得本category对应要显示或隐藏的区域
            var arrow = e.target;
            arrow.getAttribute('name') === 'arrow'
              ? (arrow = arrow)
              : (arrow = arrow.parentNode); // 若点到Arrow里面的子元素，取其父节点Arrow元素
            arrow.style.transformOrigin = '50% 50%'; // 中心旋转，可防止箭头乱跑
            // 上下旋转箭头
            if (section.style.display === 'none') {
              section.style.display = 'block';
              arrow.style.transform = 'rotate(180deg)';
            } else {
              section.style.display = 'none';
              arrow.style.transform = 'rotate(0deg)';
            }
          }}
        />
      </div>
    </div>
  );
};

// 港股收费条目
const HKRow = ({ type, boldBorder = false }) => {
  return <Row type={type} country={'hk'} boldBorder={boldBorder} />;
};
// 美股收费条目
const USRow = ({ type, boldBorder = false }) => {
  return <Row type={type} country={'us'} boldBorder={boldBorder} />;
};
// A股收费条目
const NorthboundRow = ({ type, boldBorder = false }) => {
  return <Row type={type} country={'northbound'} boldBorder={boldBorder} />;
};
// 收费条目
const Row = ({ type, country, boldBorder }) => {
  return (
    <div
      css={css`
        display: flex;
        border-bottom: ${boldBorder
          ? '1px solid #000000'
          : '0.5px solid #adadad'};
        h5 {
          margin-bottom: 0px;
          font-weight: 600;
        }
        justify-content: space-between;
        padding: 11px 0;
        ${Tablet} {
          padding: 15px 0;
        }
        a,
        a:active,
        a:visited {
          color: #ee2f79;
        }
      `}
    >
      {/* 左信息块 */}
      <div style={{ paddingRight: '0px' }}>
        <div
          css={css`
            font-weight: normal;
            font-size: 10px;
            line-height: 1;
            max-width: 150px;
            ${Tablet} {
              font-size: 16px;
              line-height: 1.25;
              max-width: 200px;
            }
          `}
        >
          <FormattedHTMLMessage id={`fee.${country}-types.${type}.name`} />
        </div>

        <div
          css={css`
            font-weight: normal;
            font-size: 8px;
            line-height: 1.5;
            ${Tablet} {
              line-height: 1.67;
              font-size: 12px;
            }
          `}
        >
          <FormattedHTMLMessage id={`fee.${country}-types.${type}.charged`} />
        </div>
      </div>
      {/* 右信息块 */}
      <div
        css={css`
          text-align: right;
        `}
      >
        <div
          css={css`
            font-size: 10px;
            font-weight: normal;
            line-height: 1;
            // white-space: nowrap;
            // max-width: 200px;
            ${Tablet} {
              line-height: 1.25;
              font-size: 16px;
            }
            ${Desktop} {
              max-width: 400px;
            }
          `}
        >
          <FormattedHTMLMessage
            id={`fee.${country}-types.${type}.description`}
          />
        </div>
        <div
          css={css`
            font-weight: normal;
            font-size: 8px;
            line-height: 1.5;
            ${Tablet} {
              line-height: 1.67;
              font-size: 12px;
            }
          `}
        >
          <FormattedHTMLMessage id={`fee.${country}-types.${type}.frequency`} />
        </div>
      </div>
    </div>
  );
};
// 切换股票【按钮】组件
const Tab = ({ children, selected, onClick }) => {
  return (
    <div
      css={css`
        position: relative;
        color: ${selected ? '#000000' : '#8b8b8b'};
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        transition: all 0.2s ease;
        text-align: center;
        border-right: 1px solid #b4b4b4;
        :last-child {
          border-right: none;
        }
        width: 167px;
        height: 37px;
        ${Tablet} {
          width: 217px;
          height: 43px;
        }
        ${Desktop} {
          width: 315px;
          height: 57px;
        }
      `}
      onClick={onClick}
    >
      <h5
        css={css`
          margin: 0px;
          // font-weight: ${selected ? 'bold' : 'normal'};
          font-size: 11px;
          line-height: 3.09;
          // border-bottom: solid 1px ${selected ? '#ee2f79' : 'white'};
          ${Tablet} {
            font-size: 12px;
            line-height: 55px;
          }
          ${Desktop} {
            font-size: 18px;
            line-height: 55px;
          }
        `}
      >
        {children}
      </h5>
      {/* 被选中按钮的底部横线 */}
      <div
        css={css`
          display: ${selected ? 'block' : 'none'};
          position: absolute;
          background: #ee2f79;
          height: 2px;
          bottom: 0;
          width: 116.9px;
          ${Tablet} {
            width: 145px;
          }
          ${Desktop} {
            width: 208px;
          }
        `}
      ></div>
    </div>
  );
};

export { Category, HKRow, USRow, NorthboundRow, Tab };
