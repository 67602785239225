import React from 'react';
import { css } from '@emotion/core';
import { Tablet, Desktop } from '../../constants/responsive';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
// 【投资组合】卡片
const AdvisoryFeeCard = ({ id }) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        border-radius: 10px;
        overflow: hidden;
        text-align: center;
        box-shadow: 0 0 15px 0 rgba(34, 34, 34, 0.08);
        max-width: 160.7px;
        width: 160.7px;
        ${Tablet} {
          width: 231.9px;
          max-width: 300px;
        }
        ${Desktop} {
          width: 278px;
        }
      `}
    >
      {/* grid 1 */}
      <div
        css={css`
          background: #ebebeb;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 79px;
          ${Tablet} {
            height: 79px;
          }
          ${Desktop} {
            height: 111px;
          }
        `}
      >
        <span
          css={css`
            font-weight: 500;
            font-size: 12px;
            line-height: 1;
            ${Tablet} {
              font-size: 12px;
              line-height: 1;
            }
            ${Desktop} {
              font-size: 16px;
              line-height: 1.25;
            }
          `}
        >
          {
            {
              en: 'Portfolio Value',
              'zh-cn': '投资组合价值',
              'zh-hk': '投資組合價值',
            }[intl.locale]
          }
        </span>
        <span
          css={css`
            font-weight: bold;
            font-size: 20px;
            line-height: 1.6;

            ${Tablet} {
              font-size: 20px;
              line-height: 1.6;
            }
            ${Desktop} {
              font-size: 35px;
              line-height: 1.57;
            }
          `}
        >
          <FormattedHTMLMessage
            id={`fee.active-portfolios-annual-fee.portfolio-${id}.row-1.text-2`}
          />
        </span>
      </div>
      {/* grid 2 */}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 80.3px;
          ${Tablet} {
            height: 80.3px;
          }
          ${Desktop} {
            height: 99.6px;
          }
        `}
      >
        <span
          css={css`
            font-weight: bold;
            margin-bottom: 0;
            line-height: 1;
            font-size: 20px;
            margin-bottom: 10px;
            ${Tablet} {
              line-height: 1;
              font-size: 20px;
              margin-bottom: 10px;
            }
            ${Desktop} {
              line-height: 0.83;
              font-size: 30px;
              margin-bottom: 9px;
            }
          `}
        >
          <FormattedHTMLMessage
            id={`fee.active-portfolios-annual-fee.portfolio-${id}.row-2.text-1`}
          />
        </span>
      </div>
    </div>
  );
};

// 【股票组合的收费】
const ActivePortfoliosAnnualFee = ({ show = true }) => {
  return (
    <div
      css={css`
        display: ${show ? 'flex' : 'none'};
        width: 100%;
        justify-content: center;
        ${Tablet} {
          width: auto;
        }
        ${Desktop} {
          width: 100%;
        }
      `}
    >
      {/* PC端布局：一行4个Card */}
      <div
        css={css`
          display: none;
          ${Tablet} {
            display: none;
          }
          ${Desktop} {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
          }
        `}
      >
        <AdvisoryFeeCard id='1' />
        <AdvisoryFeeCard id='2' />
        <AdvisoryFeeCard id='3' />
        <AdvisoryFeeCard id='4' />
      </div>
      {/* ipad与手机端布局：两行各2个Card */}
      <div
        css={css`
          margin-bottom: 14px;
          ${Tablet} {
            margin-bottom: 14px;
          }
          ${Desktop} {
            display: none;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 333px; //当手机宽度足够时，限制该行最大宽度，防止两Card间隔过大。
            margin-bottom: 14px;
            ${Tablet} {
              width: 481px;
              max-width: 481px; //因为上面定义的max-width会对ipad端有影响，故定义。
              margin-bottom: 18px;
            }
          `}
        >
          <AdvisoryFeeCard id='1' />
          {/* 当手机宽度过小时，保持最小间隔，使Card组件宽度适应变化 */}
          <div
            css={css`
              width: 11px;
              ${Tablet} {
                display: none;
              }
            `}
          ></div>
          <AdvisoryFeeCard id='2' />
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 333px;
            ${Tablet} {
              width: 481px;
              max-width: 481px;
            }
          `}
        >
          <AdvisoryFeeCard id='3' />
          <div
            css={css`
              width: 11px;
              ${Tablet} {
                display: none;
              }
            `}
          ></div>
          <AdvisoryFeeCard id='4' />
        </div>
      </div>
    </div>
  );
};

export default ActivePortfoliosAnnualFee;
