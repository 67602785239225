import React from 'react';
import { css } from '@emotion/core';
import { Tablet, Desktop } from '../../constants/responsive';
import { AdvisoryFeeCard, StockTradingFeeCard } from './FeeCard';
import ActivePortfoliosAnnualFee from './ActivePortfoliosAnnualFee';

// 【股票组合的收费】
const AnnualAdvisoryFees = ({ show = true }) => {
  return (
    <div
      css={css`
        display: ${show ? 'flex' : 'none'};
        width: 100%;
        justify-content: center;
        ${Tablet} {
          width: auto;
        }
        ${Desktop} {
          width: 100%;
        }
      `}
    >
      {/* PC端布局：一行4个Card */}
      <div
        css={css`
          display: none;

          ${Tablet} {
            display: none;
          }
          ${Desktop} {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 12px;
          }
        `}
      >
        <AdvisoryFeeCard id='1' />
        <AdvisoryFeeCard id='2' />
        <AdvisoryFeeCard id='3' />
        <AdvisoryFeeCard id='4' />
      </div>
      {/* ipad与手机端布局：两行各2个Card */}
      <div
        css={css`
          margin-bottom: 14px;
          ${Tablet} {
            margin-bottom: 14px;
          }
          ${Desktop} {
            display: none;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 333px; //当手机宽度足够时，限制该行最大宽度，防止两Card间隔过大。
            margin-bottom: 14px;
            ${Tablet} {
              width: 481px;
              max-width: 481px; //因为上面定义的max-width会对ipad端有影响，故定义。
              margin-bottom: 18px;
            }
          `}
        >
          <AdvisoryFeeCard id='1' />
          {/* 当手机宽度过小时，保持最小间隔，使Card组件宽度适应变化 */}
          <div
            css={css`
              width: 11px;
              ${Tablet} {
                display: none;
              }
            `}
          ></div>
          <AdvisoryFeeCard id='2' />
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            width: 100%;
            max-width: 333px;
            ${Tablet} {
              width: 481px;
              max-width: 481px;
            }
          `}
        >
          <AdvisoryFeeCard id='3' />
          <div
            css={css`
              width: 11px;
              ${Tablet} {
                display: none;
              }
            `}
          ></div>
          <AdvisoryFeeCard id='4' />
        </div>
      </div>
    </div>
  );
};

// 【个股的收费】
const StockTradingFees = ({ show = true }) => {
  return (
    <div
      css={css`
        display: ${show ? 'flex' : 'none'};
        width: 100%;

        justify-content: center;
        ${Tablet} {
          width: auto;
        }
        ${Desktop} {
          width: 100%;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 333.4px;

          ${Tablet} {
            width: 647.4px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
          ${Desktop} {
            width: 867.2px;
            display: flex;
            justify-content: space-between;
          }
        `}
      >
        <StockTradingFeeCard id='1' />
        <StockTradingFeeCard id='2' />
        <StockTradingFeeCard id='3' />
      </div>
    </div>
  );
};
export { AnnualAdvisoryFees, StockTradingFees, ActivePortfoliosAnnualFee };
